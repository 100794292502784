// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-par-tmp-js": () => import("./../../../src/pages/cv_par_tmp.js" /* webpackChunkName: "component---src-pages-cv-par-tmp-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-parallax-tmp-js": () => import("./../../../src/pages/parallax_tmp.js" /* webpackChunkName: "component---src-pages-parallax-tmp-js" */),
  "component---src-pages-portal-js": () => import("./../../../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-pages-scraping-tmp-js": () => import("./../../../src/pages/scraping_tmp.js" /* webpackChunkName: "component---src-pages-scraping-tmp-js" */)
}

